.paper {
  padding: 12px;
  text-align: center;
  margin-top: 10px;
}
.form {
  /* margin-top: 10px !important; */
}
.avatar {
  width: 100px !important;
  height: 100px !important;
  margin: 10px auto 10px auto !important;
}
.pageTitle {
  margin: 20px auto 20px auto;
}
.textField {
  margin: 10px auto 10px auto !important;
}
.button {
  margin: 20px auto 10px auto;
  position: relative;
}
.customError {
  color: red;
  font-size: 0.8rem;
  margin-top: 10px;
}
.progress {
  position: absolute;
}
.paper {
  padding: 12px;
}
.link {
  margin-top: 20px;
}
