#pagination {
  margin-bottom: 8px;
}
#pagination .pagination-item {
  background-color: rgba(0, 0, 0, 0.08);
  margin: 3px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: auto;
  padding: 4px;
  text-align: center;
}
#pagination .pagination-active {
  background-color: #009688;
  color: white;
}
